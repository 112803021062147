<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover" :class="{'disabled_content':loading}">
        <div class="page-inner" id="main_page">
          <div class="va-card">
            <div class="dash_card_body pad-lr-0 ov-h">
              <div v-if="errors">
                <div class="alert alert-danger">
                  <ul>
                    <li v-for="(err_msg, errindex) in errorMsg" :key="errindex">{{ err_msg }}</li>
                  </ul>
                </div>
              </div>
              <div class="header_section d-block padd-30 pt-0 pb-0">
                <h4 class="menu_header">Voucher Details</h4>
                <div class="back-btn">
                  <router-link :to="{ name: 'giftcards' }"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                  </router-link>
                  <span>{{ voucherName }}</span>
                </div>

                <div class="Vstatus row">
                  <div class="col-5"><strong>Payment Amount: </strong>{{ totalVoucherAmount }}</div>
                  <div class="col-5"><strong>Payment Status:</strong> <span class="orangetxt">{{ voucherPaymentStatus }}</span></div>
                </div>

              </div>
              <hr>

             
              <div class="col-md-12">
                <div class="Vdetails">
                  <h2>Voucher Details</h2>
                  <ul>
                    <li><strong>Name: </strong>{{ voucherName }}</li>
                    <li><strong>Reference: </strong> {{reference}}</li>
                    <li><strong>From Name: </strong> {{ fromName == 'Canivore Restuarant' ? 'Artcaffe' : fromName }}</li>
                  </ul>
                  <div class="mt-4">
                    <span v-if="paidFor">
                      <button
                        type="button" :class="['btn btn-success btn-sm mr-2',{'disabled_content': user.role.id == 3}]">Print
                      </button>
                      <button
                        type="button" @click="exportGiftCards(reference)" :class="['btn custom-orange btn-sm mr-2',{'disabled_content': user.role.id == 3}]"><span v-if="exportLoader == reference "> <i class="fa fa-spinner fa-spin"></i> </span> Export
                      </button>
                    </span>
                    
                    <span v-if="bulkGenerated">
                      <button type="button" data-toggle="modal" data-target="#BulkAssignModal"
                        :class="['btn btn-info btn-sm mr-2',{'disabled_content': user.role.id == 3}]">Bulk Assign</button>
                    </span>
                    <span v-if="voucherCodes.length > 1">
                      <button  type="button" data-toggle="modal" data-target="#BulkVoidModal"
                        class="btn btn-orange btn-sm mr-2">Bulk Void</button>
                    </span>
                    <!-- modal for bulk assign -->
                    <div class="modal fade" id="BulkAssignModal" tabindex="-1" role="dialog"
                      aria-labelledby="redeemModalLabel" aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="BulkAssignModalLabell">Voucher Amount:
                              {{ formatPrice(VoucherAmount) }}</h5>
                            <button type="button" id="bulk_assign_cls_btn" class="close" data-dismiss="modal"
                              aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <form @submit.prevent="bulkAssign()">
                            <div class="modal-body" style="padding:21px !important">
                              <div class="form-group">
                                <label class="control-label">First Name</label>
                                <div>
                                  <input type="text" id="redeem_amount" class="form-control input-lg "
                                    v-model="firstName" @blur="v$.firstName.$touch">
                                </div>
                                <div class="input_error" v-if="v$.firstName.$error">
                                  <span v-if="v$.firstName.required.$invalid">First Name is required</span>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label">Last Name</label>
                                <div>
                                  <input type="text" id="redeeem_checkid" class="form-control input-lg" name="check_id"
                                    v-model="lastName">
                                </div>
                                <div class="input_error" v-if="v$.lastName.$error">
                                  <span v-if="v$.lastName.required.$invalid">Last Name is required</span>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label">Email</label>
                                <div>
                                  <input type="text" id="redeeem_checkid" class="form-control input-lg" name="check_id"
                                    v-model="email">
                                </div>
                                <div class="input_error" v-if="v$.email.$error">
                                  <span v-if="v$.email.required.$invalid">Email is required</span>
                                  <span v-if="v$.email.email.$invalid">Email is invalid</span>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label">Phone</label>
                                <div>
                                  <input type="text" id="redeeem_checkid" class="form-control input-lg" name="check_id"
                                    v-model="phone">
                                </div>
                                <div class="input_error" v-if="v$.phone.$error">
                                  <span v-if="v$.phone.required.$invalid">Phone is required</span>
                                </div>
                              </div>

                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                              <button type="submit" class="btn btn-primary redeem_submit"><span v-if="bulkAssigning"><i
                                class='fa fa-spinner fa-spin '></i></span> Assign</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <!-- bulkvoid -->
                    <div class="modal fade" id="BulkVoidModal" tabindex="-1" role="dialog"
                      aria-labelledby="voidModalLabel" aria-hidden="true">
                      <div class="modal-dialog  " role="document">
                        <div class="modal-content">
                          <div class="modal-header">

                            <h5 class="modal-title" id="voidModalLabel">Void Gift card
                              <strong>( <span v-for="(vcode,index) in voucherCodes" :key="index">{{ vcode }} <span v-if="index+1 < voucherCodes.length">,</span> </span>)</strong></h5>
                            <button type="button" id="bulk_void_close_btn" class="close"
                              data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <form @submit.prevent="BulkvoidVoucher()">
                            <div class="modal-body" style="padding:21px !important">
                              <div class="form-group">
                                <label class="control-label" for="voidReason">Void Reason</label>
                                <select v-model="reason" id='voidReason' class="form-control">
                                  <option value="Customer Refunded">Customer Refunded</option>
                                  <option value="Wrong recipient">Wrong recipient</option>
                                  <option value="Fraudulent activity">Fraudulent activity</option>
                                  <option value="other">Other reasons</option>
                                </select>
                              </div>
                              <div class="form-group">
                                <label class="control-label" for="voidRemarks">Remarks</label>
                                <textarea class="form-control" v-model="remarks" id="voidRemarks" rows="3"
                                  cols="20"></textarea>
                              </div>
                
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                              <button type="submit" class="btn btn-primary void_btn"><span
                                v-if="bulkvoidingVoucher"><i
                                class='fa fa-spinner fa-spin '></i></span>Bulk Void</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <!-- end modal -->
                  </div>
                </div>
                 
              </div>
              
              
              <hr>
              <div class="dash_card_body">
                <div class="row mt-3">
                  <div class="col-md-8"></div>
                  <div class="col-md-3">
                    <div class="form-group has-search">
                      <span class="fa fa-search form-control-feedback"></span>
                      <input type="text" v-model="search" @Keyup="searchVouchers()" class="form-control"
                        placeholder="Search">
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="form-group has-search">
                      <button class="btn btn-orange search mr-2" @click="searchVouchers()">
                        <span v-if="searchingType == 'search'"><span v-html="searchspinner"></span></span> Search
                      </button>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table_section">
                      <table class="table  table-hover">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Voucher Code</th>
                            <th scope="col">Voucher Amount</th>
                            <th scope="col">Recipient</th>
                            <th scope="col">Time used</th>
                            <th scope="col">Max Usage</th>
                            <th scope="col">Status</th>
                            <th width="27%" scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>

                          <tr v-for="voucherItem in voucherItems" :key="voucherItem.id">
                            <td>
                              <input type="checkbox" :id="'voucher_'+voucherItem.code" @click="AddCodes(voucherItem.masked_code)">
                            </td>
                            <td>
                              <router-link :to="{ name: 'redemption', params: { vouche_id: voucherItem.id } }">
                                {{ voucherItem.code }}
                              </router-link>
                            </td>
                            <td>
                              <div v-if="voucherItem.percentage_based">
                                <b>{{ voucherItem.amount }} % </b> <br>
                              </div>
                              <div v-else>
                                <small><b>Amount: </b></small> {{ formatPrice(voucherItem.amount) }} <br>
                                <small><b>Balance:</b></small> {{ formatPrice(voucherItem.balance) }}
                              </div>
                              
                            </td>
                            <td>
                              <div v-if="voucherItem.recipient_first_name">
                                <b>Name:</b>{{ voucherItem.recipient_first_name + " " + voucherItem.recipient_last_name }}
                                <br>
                                <b>Email:</b> {{ voucherItem.recipient_email }}<br>
                                <b>Phone:</b>{{ voucherItem.recipient_phone }}

                              </div>
                              <div v-else>-</div>
                            </td>
                            <td>
                              <span v-if="voucherItem.num_time_used > 0">
                                {{ voucherItem.num_time_used }}
                              </span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              {{ voucherItem.number_of_installments }}
                            </td>
                            <td>
                              {{ voucherStatus[voucherItem.status] }}
                              <span v-if="voucherStatus[voucherItem.status] == 'Void'">
                                <br><strong><small>Reason: {{ voucherItem.void_reason }} </small> </strong>
                                <span v-if="voucherItem.void_reason == 'other'">
                                  <br><small>{{ voucherItem.void_remarks }}</small>
                                </span>
                              </span>
                            </td>
                            <td>
                              <span v-if="voucherStatus[voucherItem.status] != 'Void'">
                                <span v-if="voucherItem.status == 1 || voucherItem.status == 0">
                                  <button @click="veryVoucher(voucherItem.code)" :disabled="disabled" type="button"
                                    class="btn btn_voucher  btn-sm mr-2 mt-1"><span
                                    v-if="verifySpinner == voucherItem.code" v-html="spinner"></span><span
                                    v-else>Verify</span> </button>
                                </span>
                                <span
                                  v-if="(!voucherItem.bulk_generated && voucherItem.payment_status == 'COMPLETED') || (voucherItem.bulk_generated && voucherItem.recipient_first_name !='')">
                                  <span v-if="!voucherItem.used">
                                    <button type="button" data-toggle="modal"
                                      :data-target="'#redeemModal_' + voucherItem.id"
                                      class="btn btn-success btn-sm mr-2 mt-1">Redeem</button>

                                    <button type="button" :class="['btn btn-success btn-sm mr-2 mt-1',{'disabled_content': user.role.id == 3}]"
                                      @click="print(voucherItem.code, 'voucher')">Print</button>
                                    <button type="button" :class="['btn btn-success btn-sm mr-2 mt-1',{'disabled_content': user.role.id == 3}]" data-toggle="modal"
                                      :data-target="'#sendNotification' + voucherItem.code">Resend</button>
                                    <button type="button" :class="['btn btn_voucher btn-sm mt-1',{'disabled_content': user.role.id == 3}]" data-toggle="modal"
                                      :data-target="'#voidModal_' + voucherItem.code"> Void</button>
                                  </span>
                                </span>
                                <span v-if="!voucherItem.recipient_first_name">
                                  <button type="button" data-toggle="modal" :data-target="'#AssignModal_' + voucherItem.id"
                                    :class="['btn btn-success btn-sm mr-2',{'disabled_content': user.role.id == 3}]">Assign</button>
                                </span>
                              </span>
                              <span v-else>
                                N/A
                              </span>
                            </td>
                            <!-- redeem modal -->
                            <div class="modal fade" :id="'redeemModal_' + voucherItem.id" tabindex="-1" role="dialog"
                              aria-labelledby="redeemModalLabel" aria-hidden="true">
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title" id="redeemModalLabel">Redeem Voucher - <span
                                      class='voucher'>{{ voucherItem.code }}</span></h5>
                                    <button type="button" :id="'redeem_close_btn_' + voucherItem.code" class="close"
                                      data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <form @submit.prevent="redeemVoucher(voucherItem.code)">
                                    <div class="modal-body" style="padding:21px !important">
                                      <input type="hidden" name="_token">
                                      <strong>Voucher amount: </strong><span
                                        class='voucher_amount'>{{ formatPrice(voucherItem.amount) }}</span><br>
                                      <strong>Available balance: </strong><span
                                        class='voucher_balance'>{{ formatPrice(voucherItem.balance) }}</span>
                                      <p></p>
                                      <div class="form-group">
                                        <label class="control-label">Amount</label>
                                        <div>
                                          <input type="text" id="redeem_amount" class="form-control input-lg"
                                            v-model="redeemAmount">
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label class="control-label">Check Id</label>
                                        <div>
                                          <input type="text" id="redeeem_checkid" class="form-control input-lg"
                                            name="check_id" v-model="checkID" required>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label for="redeen_location">Select Branch</label>
                                        <select class="form-control" v-model="storeId" id="redeem_location">
                                          <option v-for="store in stores" :key="store.id" :value="store.id">
                                            {{ store.branch_name }}</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                      <button type="submit" class="btn btn-primary redeem_submit"><span
                                        v-if="redeemingVoucher == voucherItem.code"><i
                                        class='fa fa-spinner fa-spin '></i></span> Redeem</button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <!-- end redeem mail -->
                            <!-- assign modal -->
                            <div class="modal" :id="'AssignModal_' + voucherItem.id" tabindex="-1" role="dialog"
                              aria-labelledby="redeemModalLabel" aria-hidden="true">
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title" id="BulkAssignModalLabell">Voucher Amount: {{
                                      formatPrice(voucherItem.amount)
                                    }}</h5>
                                    <button type="button" :id="'close_btn_' + voucherItem.id" class="close"
                                      data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <form @submit.prevent="assignTorecepient(voucherItem.code, voucherItem.id)">
                                    <div class="modal-body" style="padding:21px !important">
                                      <div class="form-group">
                                        <label class="control-label">First Name</label>
                                        <div>
                                          <input type="text" id="redeem_amount" class="form-control input-lg "
                                            v-model="firstName" @blur="v$.firstName.$touch">
                                        </div>
                                        <div class="input_error" v-if="v$.firstName.$error">
                                          <span v-if="v$.firstName.required.$invalid">First Name is required</span>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label class="control-label">Last Name</label>
                                        <div>
                                          <input type="text" id="redeeem_checkid" class="form-control input-lg"
                                            name="check_id" v-model="lastName">
                                        </div>
                                        <div class="input_error" v-if="v$.lastName.$error">
                                          <span v-if="v$.lastName.required.$invalid">Last Name is required</span>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label class="control-label">Email</label>
                                        <div>
                                          <input type="text" id="redeeem_checkid" class="form-control input-lg"
                                            name="check_id" v-model="email">
                                        </div>
                                        <div class="input_error" v-if="v$.email.$error">
                                          <span v-if="v$.email.required.$invalid">Email is required</span>
                                          <span v-if="v$.email.email.$invalid">Email is invalid</span>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label class="control-label">Phone</label>
                                        <div>
                                          <input type="text" id="redeeem_checkid" class="form-control input-lg"
                                            name="check_id" v-model="phone">
                                        </div>
                                        <div class="input_error" v-if="v$.phone.$error">
                                          <span v-if="v$.phone.required.$invalid">Phone is required</span>
                                        </div>
                                      </div>

                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                      <button type="submit" class="btn btn-primary redeem_submit"><span
                                        v-if="assign"><span v-html="spinner"></span></span> Assign</button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>

                            <!-- void modal modal -->
                            <div class="modal fade" :id="'voidModal_' + voucherItem.code" tabindex="-1" role="dialog"
                              aria-labelledby="voidModalLabel" aria-hidden="true">
                              <div class="modal-dialog  " role="document">
                                <div class="modal-content">
                                  <div class="modal-header">

                                    <h5 class="modal-title" id="voidModalLabel">Void Gift card
                                      <strong>({{ voucherItem.code }})</strong></h5>
                                    <button type="button" :id="'void_close_btn_' + voucherItem.code" class="close"
                                      data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <form @submit.prevent="voidVoucher(voucherItem.code)">
                                    <div class="modal-body" style="padding:21px !important">
                                      <div class="form-group">
                                        <label class="control-label" for="voidReason">Void Reason</label>
                                        <select v-model="reason" id='voidReason' class="form-control">
                                          <option value="Customer Refunded">Customer Refunded</option>
                                          <option value="Wrong recipient">Wrong recipient</option>
                                          <option value="Fraudulent activity">Fraudulent activity</option>
                                          <option value="other">Other reasons</option>
                                        </select>
                                      </div>
                                      <div class="form-group">
                                        <label class="control-label" for="voidRemarks">Remarks</label>
                                        <textarea class="form-control" v-model="remarks" id="voidRemarks" rows="3"
                                          cols="20"></textarea>
                                      </div>
                                      <div class="form-check">
                                        <input type="checkbox" v-model="regenerate" :true-value="1" :false-value="0"
                                          class="form-check-input" id="regenerate">
                                        <label class="form-check-label" for="regenerate">Regenerate?</label>
                                      </div>
                                      <div class="recipientDetails" v-if="regenerate">
                                        <div class="form-group">
                                          <label for="recipientFirstName">First Name</label>
                                          <input type="text" class="form-control" v-model="recepientFirstName"
                                            id="recipientFirstName">
                                        </div>
                                        <div class="form-group">
                                          <label for="recipientLastName">Last Name</label>
                                          <input type="text" class="form-control" v-model="recepientLastName"
                                            id="recipientLastName">
                                        </div>
                                        <div class="form-group">
                                          <label for="recipientEmail">Email</label>
                                          <input type="text" class="form-control" v-model="recepientEmail"
                                            id="recipientEmail">
                                        </div>
                                        <div class="form-group">
                                          <label for="recipientPhone">Phone</label>
                                          <input type="text" class="form-control" v-model="recepientPhone"
                                            id="recipientPhone">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                      <button type="submit" class="btn btn-primary void_btn"><span
                                        v-if="voidingVoucher == voucherItem.code"><i
                                        class='fa fa-spinner fa-spin '></i></span> Void</button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>


                            <!-- send notification mail modal -->
                            <div class="modal fade" :id="'sendNotification' + voucherItem.code" tabindex="-1" role="dialog"
                              aria-labelledby="voidModalLabel" aria-hidden="true">
                              <div class="modal-dialog  " role="document">
                                <div class="modal-content">
                                  <div class="modal-header">

                                    <h5 class="modal-title" id="voidModalLabel">Send Notification for Voucher:
                                      <strong>({{ voucherItem.code }})</strong></h5>
                                    <button type="button" :id="'sendN_close_btn_' + voucherItem.code" class="close"
                                      data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <form @submit.prevent="sendNotification(voucherItem.code, voucherItem.id)">
                                    <div class="modal-body" style="padding:21px !important">
                                      <div class="form-group">
                                        <label class="control-label">Phone</label>
                                        <div>
                                          <input type="text" id="redeem_amount" class="form-control input-lg "
                                            v-model="notificationPhone[voucherItem.id]">
                                        </div>

                                      </div>

                                      <div class="form-group">
                                        <label class="control-label">Email</label>
                                        <div>
                                          <input type="text" id="redeem_amount" class="form-control input-lg "
                                            v-model="notificationEmail[voucherItem.id]" @blur="v$.firstName.$touch">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                      <button type="submit" :class="['btn btn-primary void_btn',{'disabled_content': user.role.id == 3}]"><span
                                        v-if="sendingNotifications == voucherItem.code"><i
                                        class='fa fa-spinner fa-spin '></i></span>Send Notification</button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                        <tfoot v-if="!loading">
                          <tr>
                            <td colspan="9">
                              <div id="pagination" v-if="totalPages > 1">
                                <ul class="showItems">
                                  <li>Show Items:
                                    <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                      <option :value="10">10</option>
                                      <option :value="20">20</option>
                                      <option :value="30">30</option>
                                      <option :value="40">40</option>
                                      <option :value="50">50</option>
                                      <option :value="100">100</option>
                                    </select>
                                  </li>

                                </ul>
                                <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                                  @pagechanged="onPageChange" />

                              </div>

                            </td>
                          </tr>
                        </tfoot>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="main-loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import { useToast } from "vue-toastification";
import Pagination from '@/pages/pagination/pagination.vue';
const toast = useToast();

export default {
  components: {
    Pagination
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      giftLoading: false,
      voucherID: this.$route.params.id,
      voucherItems: [],
      voucherStatus: ['Pending', 'Active', 'Void', 'Used'],
      firstName: '',
      notificationPhone: [],
      notificationEmail: [],
      VoucherAmount: 0,
      totalVoucherAmount: 0,
      voucherPaymentStatus: '',
      voucherName: '',
      reference:'',
      lastName: '',
      phone: '',
      email: '',
      bulkGenerated: 0,
      fromName: '',
      loading: false,
      redeemAmount: '',
      recepientFirstName: '',
      recepientLastName: '',
      recepientEmail: '',
      recepientPhone: '',
      stores: [],
      verifySpinner: 0,
      spinner: "<i class='fa fa-spinner fa-spin '></i>",
      disabled: false,
      assign: false,
      errors: false,
      errorMsg: [],
      regenerate: 0,
      reason: '',
      remarks: '',
      storeId: '',
      checkID: '',
      voidingVoucher: false,
      bulkAssigning: false,
      sendingNotifications: null,
      redeemingVoucher: null,
      page: 1, 
      totalPages: 0,  
      totalRecords: 0,
      recordsPerPage: 10,
      debounce: null,
      exportLoader:false,
      paidFor:false,
      search:'',
      voucherCodes:[],
      bulkvoidingVoucher:false
    }
  },
  validations() {
    return {
      firstName: { required },
      lastName: { required },
      phone: { required },
      email: { required, email },
    }
  },

  mounted: async function () {
    this.getGiftVoucher();
    this.getBranches();
  },
  methods: {
    AddCodes(code){
      if(document.getElementById(`voucher_${code}`).checked){
        if(this.voucherCodes.filter((x) => x ==code).length == 0){
          this.voucherCodes.push(code)
        }
      }else{
        this.voucherCodes = this.voucherCodes.filter((x) => x !=code)
      }
    },
    searchVouchers() {    
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
          this.getGiftVoucher();
      }, 1000)
    },
    async exportGiftCards(reference){
      try {
        this.exportLoader = reference
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        let url = `${this.dashBaseUrl}/export-giftcard-data/${reference}`;
        url = new URL(url);
        url.searchParams.set('merchant_id',this.merchantID)
        const response = await this.axios.get(url, config)
        if(response.status === 200){
          this.exportLoader = false
          const giftcardDetails = []
          const giftcards = response.data.data
          for(const giftcard of Object.values(giftcards)){ 
            giftcardDetails.push({
              'Date':this.formatDate(giftcard.created_at),
              'Name':giftcard.title,
              'Code':giftcard.code,
              'Amount':giftcard.amount,
              'Balance':giftcard.balance,
              'Type': giftcard.bulk_generated ? 'Bulk Generated' : 'Regular',
              'Buyer Name':giftcard.customer_first_name +' '+giftcard.customer_last_name,
              'Buyer Email':giftcard.customer_email,
              'Buyer Phone':giftcard.customer_phone,
              'Recipient Name':giftcard.recipient_first_name +' ' +giftcard.recipient_last_name,
              'Recipient Email':giftcard.recipient_email,
              'Recipient Phone':giftcard.recipient_phone,
              'Reference':giftcard.reference,
              'Voucher_url':giftcard.voucher_url
            })
          }
          const fileName = Date.now() +'_giftcards'
          this.exportExcel(giftcardDetails, fileName)
        }
      } catch (error) {
        this.exportLoader = false
        if (error.response &&  error.response.status === 401) {
            this.$router.push({
                name: 'login'
            });
        }
        
      }
      
    },
    async getGiftVoucher() {
      const loader = document.getElementById('main-loader');
      loader.style.display = 'block';
      this.loading = true;
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      let url = `${this.dashBaseUrl}/giftvoucher/${this.voucherID}`;
      url = new URL(url);
      url.searchParams.set('merchant_id', this.merchantID);
      if(this.search ==''){
        url.searchParams.set('page', this.page);
      }else{
        url.searchParams.set('search', this.search);
      }
      url.searchParams.set('size', this.recordsPerPage);
          
      this.axios.get(url, config).then((response) => {
        this.loading = false;
        loader.style.display = 'none';
        if (response.status === 200) {
          this.voucherItems = response.data.data.data.vouchers.data;
          this.voucherName = this.voucherItems[0].short_description;
          this.reference = this.voucherItems[0].reference
          this.fromName = this.voucherItems[0].customer_first_name;
          this.bulkGenerated = this.voucherItems[0].bulk_generated;
          this.VoucherAmount = this.voucherItems[0].amount;
          this.voucherPaymentStatus = this.voucherItems[0].payment_status;
          // populating phone and email on notification modal
          for (let x = 0; x < this.voucherItems.length; x++) {
            this.totalVoucherAmount += parseFloat(this.voucherItems[x].amount)
            this.notificationPhone[this.voucherItems[x].id] = this.voucherItems[x].recipient_phone ? this.voucherItems[x].recipient_phone : '';
            this.notificationEmail[this.voucherItems[x].id] = this.voucherItems[x].recipient_email ? this.voucherItems[x].recipient_email : '';
            // 
          }
          for (let x = 0; x < this.voucherItems.length; x++) {
            if(this.voucherItems[x].bulk_generated && (this.voucherItems[x].recipient_email !='' || this.voucherItems[x].recipient_phone) ){
              this.paidFor =true;
              break;
            } else if(!this.voucherItems[x].bulk_generated && this.voucherItems[x].payment_status =='COMPLETED'){
              this.paidFor =true;
              break;
            }else{
              this.paidFor =false
            }
          }
          this.totalPages = Math.ceil(response.data.data.data.vouchers.total / this.recordsPerPage)
          this.totalRecords = response.data.data.data.vouchers.total
        }
      }).catch((err) => {
        this.loading = false;
        loader.style.display = 'none';
        console.log('error', err);
      })

    },
    async veryVoucher(code) {
      //
      this.disabled = true;
      this.verifySpinner = code;
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      const url = `${this.dashBaseUrl}/verify`;
      const payload = {
        "code": code,
        "merchant_id": parseInt(this.merchantID),
      };
      this.axios.post(url, payload, config).then((response) => {
        if (response.status === 200) {
          toast.success(response.data.data.message + " " + "code: " + code);
        }
      }).catch((err) => {
        if (err.response.status === 400) {
          this.errorMsg = err.response;
          toast.error(err.response.data.errors.message)
        } else if (err.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }

      }).finally(() => {
        this.verifySpinner = 0;
        this.disabled = false;
      });
    },

    async getBranches() {
      const url = `${this.baseUrl}/branch?merchant_id=${this.merchantID}`;
      this.axios.get(url, this.apiRequestConfig()).then((response) => {
        if (response.status === 200) {
          this.stores = response.data.data;
        }
      }).catch((err) => {
        console.log('error', err);
      })

    },
    async assignTorecepient(code, voucherid) {
      this.assign = true;
      this.errors = false;
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        this.assign = false;
        return;
      }
      const url = `${this.dashBaseUrl}/assign`
      const formData = new FormData();
      formData.append('recipient_first_name', this.firstName);
      formData.append('recipient_last_name', this.lastName);
      formData.append('code', code);
      formData.append('reference', this.voucherID);
      formData.append('recipient_email', this.email);
      formData.append('recipient_phone', this.phone);
      formData.append('bulk_assign', 0);
      formData.append('merchant_id', this.merchantID);
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Accept': 'application/json'
        }
      };
      this.axios.post(url, formData, config).then((response) => {
        if (response.status === 200) {
          this.toast.success('gift voucher has been assigned to the the recepient');
        }
      })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = true;
            this.errorMsg = error.response.data.errors;
          } else if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        }).finally(() => {
          this.getGiftVoucher();
          this.assign = false;
          this.firstName = '';
          this.lastName = '';
          this.email = '';
          this.phone = '';
          const closeBtn = document.getElementById('close_btn_' + voucherid);
          if (closeBtn) {
            closeBtn.click();
          }
          // 
        });
    },
    async bulkAssign() {
      this.errors = false;
      this.bulkAssigning = true;
      const isFormCorrect = await this.v$.$validate()
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (!isFormCorrect) {
        this.spinner = null;
        return;
      }
      const url = `${this.dashBaseUrl}/assign`
      const payload = {
        'recipient_first_name': this.firstName,
        'recipient_last_name': this.lastName,
        'reference': this.voucherID,
        'recipient_email': this.email,
        'recipient_phone': this.phone,
        'bulk_assign': 1,
        'merchant_id': this.merchantID,
        'codes':this.voucherCodes
      };
      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.token}`
        }
      };
      this.axios.post(url, payload, config).then((response) => {
        if (response.status === 200) {
          this.toast.success('gift voucher has been bulk assigned to the the recepient');
        }
      })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = true;
            this.errorMsg = error.response.data.errors;
          }
        }).finally(() => {
          this.getGiftVoucher();
          this.bulkAssigning = false;
          this.firstName = '';
          this.lastName = '';
          this.email = '';
          this.phone = '';
          this.voucherCodes = [];
          const closeBtn = document.getElementById('bulk_assign_cls_btn');
          if (closeBtn) {
            closeBtn.click();
          }
        })
    },
    async BulkvoidVoucher(){
      this.bulkvoidingVoucher = true
      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.token}`
        }
      };
      const url = `${this.dashBaseUrl}/void`;
      const payload = {
        'code': this.voucherCodes,
        'reason': this.reason,
        'remarks': this.remarks,
        'regenerate': this.regenerate,
        'first_name': this.recepientFirstName,
        'last_name': this.recepientLastName,
        'email': this.recepientEmail,
        'phone': this.recepientPhone,
        'merchant_id': this.merchantID,
      }
      this.axios.post(url, payload, config).then((response) => {
        if (response.status == 200) {
          this.getGiftVoucher();
        }
      }).catch((err) => {
        console.log('void erro', err)
      }).finally(() => {
        this.voucherCodes = [];
        this.bulkvoidingVoucher = false;
        const closeBtn = document.getElementById('bulk_void_close_btn');
        if (closeBtn) {
          closeBtn.click();
        }
      })
    },
    async voidVoucher(code) {
      this.voidingVoucher = code;
      // void
      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.token}`
        }
      };
      const url = `${this.dashBaseUrl}/void`;
      const payload = {
        'code': code,
        'reason': this.reason,
        'remarks': this.remarks,
        'regenerate': this.regenerate,
        'first_name': this.recepientFirstName,
        'last_name': this.recepientLastName,
        'email': this.recepientEmail,
        'phone': this.recepientPhone,
        'merchant_id': this.merchantID,
      }
      this.axios.post(url, payload, config).then((response) => {
        if (response.status == 200) {
          this.getGiftVoucher();
        }
        // 
      }).catch((err) => {
        console.log('void erro', err)
      }).finally(() => {
        this.voidingVoucher = false;
        const closeBtn = document.getElementById('void_close_btn_' + code);
        if (closeBtn) {
          closeBtn.click();
        }
      })
    },
    redeemVoucher(code) {
      this.errors = false;
      this.redeemingVoucher = code;
      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.token}`
        }
      };
      if (this.checkID == ''){
        toast.error("please provide check id")
        this.redeemingVoucher = null
        return
      }
      const url = `${this.dashBaseUrl}/redeem`
      const payload = {
        'code': code,
        'location_id': this.storeId,
        'check_id': this.checkID,
        'amount': this.redeemAmount,
        'merchant_id': this.merchantID,
      };
      this.axios.post(url, payload, config).then((response) => {
        if (response.status === 200) {
          toast.success('voucher was redeemed successfully');
          this.getGiftVoucher();
        }
      }).catch((error) => {
        if (error.response.status === 400) {
          this.errors = true;
          let errorms = '';
          for(let x=0;x< error.response.data.errors.length;x++){
            errorms = error.response.data.errors[x] + ','
          }
          toast.error(errorms);
        } else if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      }).finally(() => {
        this.redeemingVoucher = null;
        const closeBtn = document.getElementById('redeem_close_btn_' + code);
        if (closeBtn) {
          closeBtn.click();
        }
      });
    },
    // print(code){
    //   const url = this.dashBaseUrl
    //   const baseUrl = url.replace("/api/v1", "/")
    //   const formatedUrl = baseUrl+"print?ref="+code+"&merchant="+this.merchantID+"&type=voucher";
    //   window.open(formatedUrl, '_blank');
    // },
    sendNotification(code, voucherid) {
      this.sendingNotifications = code;
      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.token}`
        }
      };
      const payload = {
        'code': code,
        'phone': this.notificationPhone[voucherid],
        'email': this.notificationEmail[voucherid],
        'merchant_id': this.merchantID,
      }
      const url = `${this.dashBaseUrl}/send-notification`;
      this.axios.post(url, payload, config).then((response) => {
        if (response.status === 200) {
          toast.success('notification sent successfully');
        }
      }).catch((error) => {
        console.log('err', error);
      }).finally(() => {
        this.sendingNotifications = null;
        const closeBtn = document.getElementById('sendN_close_btn_' + code);
        if (closeBtn) {
          closeBtn.click();
        }
      })
    },
    onPageChange(page) {
        this.page = page
        this.getGiftVoucher();
    },
    onChangeRecordsPerPage() {
        this.getGiftVoucher();
        // ds
    }

  },
  mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>
